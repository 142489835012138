@mixin ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemIcons{
  display: flex;
  flex-direction: row;
  font-size: 18px;
  /*

  justify-content: center;
  margin-right: 1rem !important;
  */
  .itemIcon{
    margin: 3px;
  }
}
