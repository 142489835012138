/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
.hasDatepicker{
  // background-image: url("/images/icons/cal.png");
  background-repeat: no-repeat;
  background-position-x: 80px ;
  background-position-y: 5px ;
  width: 110px;
}

.fg .btn-bar{
  text-align: right;
  padding: 0 10px 10px;
}

.pac-container {
  z-index: 10000 !important;
}

.input-error{
  background-color: #f0dcde;
}

.Select--multi .Select-value{
  color: #37362e;
  border:1px solid #dcdcdc;
  background: #f5f5f5;
  font-size: 13px;
}
.Select--multi .Select-value-icon{
  float: right;
  font-size: 14px;
  line-height:1;
  padding-top:3px;
  border-right: 0;
}
td.timerow{
  border-left: 3px solid #40c1fb;
  margin-left: 10px;
  // background: url("/img/point.png") no-repeat;
  background-size: 20px 20px;
  background-position: -12px center;
}
.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.fg-horizontal .form-group{
  display:table;
  width:100%
}

.fg-horizontal .form-group > .fg-label{
  display:table-cell;
  width: 80px;
  text-align: right;
  padding-right: 10px;
  line-height: 1.1;
  vertical-align: middle;
}

.fg-horizontal .form-group > div{
  display:table-cell;
}

.fg .form-group{
  margin-bottom: 0;
}
.fg-in{
  padding: 10px;
}
.fg{
  display:  grid;
  grid-template-columns: 1;
  grid-gap: 10px;
  -background-color: #2196F3;
  padding: 10px;
  .fg-label{
    margin: 0;
  }
}
/* Small devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {

}

/* Medium devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  .fg{
    display:  grid;
    grid-template-columns: 1;
    grid-gap: 10px;
    -background-color: #2196F3;
    padding: 10px;
  }
  .fg-compact{
    grid-gap: 5px;
  }
  .fg-in{
    display:  inline-grid;
    grid-template-columns: 1;
    grid-gap: 10px;
    padding: 10px;
  }

  .fg-1{
    grid-template-columns: auto;
  }

  .fg-2{
    grid-template-columns: auto auto;
  }

  .fg-2e{
    grid-template-columns: auto auto;
  }

  .fg-3{
    grid-template-columns: auto auto auto;
  }

  .span2 {
    grid-column: auto / span 2
  }
}

/* Large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {

}
