@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Logicpanel KIT styles
@import 'components/lpui/styles/style'; // Logicpanel styles

@import 'components/platform/formgrid';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* JQUERY UI */
.ui-dialog .ui-dialog-title{
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.8px;
}
.ui-dialog {
  padding: 0;
  > .ui-widget-header {
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  .ui-dialog-titlebar-close {
    border: 0 !important;
    background: none;
    margin-right: 4px;
    .ui-icon.ui-icon-closethick {
      background-image: url('./resources/icons/close2.png');
      background-position: center;
      background-size: 20px 20px;
      opacity: 0.6;
    }
  }
  .ui-dialog-titlebar-close:focus {
    outline: 0 !important;
  }
  .dialog-footer {
    padding: 10px 0px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    button + button {
      margin-bottom: 0;
      margin-left: 8px;
    }
  }

  &.ui-widget.ui-widget-content{
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd
  }
}
.ui-widget-content{
  color: inherit;
}
.ui-widget-content a.btn-primary{
  color: #fff!important;
}
.dialog-compact .ui-dialog-content {
  margin: 0;
  padding: 0;
}
[data-kit-theme="default"] {
  .ant-select{
    display: block;
  }
  .ant-menu-item a{
    color: #f9f9f9;
  }
}
[data-kit-theme='dark'] {
  .ant-select{
    display: block;
  }
  .ui-widget-header {
    background-color: #0c0c1b;
    color: #F9f9f9;
    border-color: #0c0c1b;
  }
  .ui-widget.ui-widget-content{
    border-color: #0c0c1b;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5), 0 6px 16px 0 rgba(0, 0, 0, 0.25), 0 9px 28px 8px rgba(0, 0, 0, 0.25);
  }
  .ui-widget-content{
    background-color: #141322;
    color: #F9f9f9;

    .dialog-footer {
      padding: 10px 0px;
      text-align: right;
      background: transparent;
      border-top: 1px solid #232135;
      border-radius: 0 0 4px 4px;
      button + button {
        margin-bottom: 0;
        margin-left: 8px;
      }
    }

  }
  .ui-dialog-titlebar-close {
    .ui-icon.ui-icon-closethick {
      background-image: url('/resources/icons/icon_w.png');
      background-position: center;
      background-size: 12px 12px;
      opacity: 1;
    }
  }

  .ui-widget-overlay{
    background: rgba(20, 19, 34, 0.84);
    opacity: 1;
  }
}

.cui__utils__content{
  padding: 3px;
}



/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  -box-shadow: inset 0 0 1px rgb(1, 212, 103);
  background: rgb(63, 150, 122);
}
::-webkit-scrollbar-track {
  -box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
} */

.crud-container .ant-table.ant-table-small tbody > tr > td.ant-table-cell{
  padding: 3px 5px;
  line-height: 1.1;
}

// timepicker ok button
.lp-timepicker .ant-picker-ok{
  display: none !important;
}
