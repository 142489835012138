/* Medium devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {
  .fg {
    display: grid;
    grid-template-columns: 1;
    grid-gap: 8px;
    padding: 0;
  }
  .fg-compact {
    grid-gap: 5px;
  }
  .fg-in {
    display: inline-grid;
    grid-template-columns: 1;
    grid-gap: 10px;
    padding: 10px;
  }

  .fg-1 {
    grid-template-columns: auto;
  }

  .fg-2 {
    grid-template-columns: auto auto;
  }

  .fg-2e {
    grid-template-columns: 50% auto;
  }

  .fg-3 {
    grid-template-columns: auto auto auto;
  }

  .span2 {
    grid-column: auto / span 2;
  }

  .fg-horizontal .form-group {
    display: table;
    width: 100%;
  }

  .fg-horizontal .form-group > .fg-label {
    display: table-cell;
    width: 120px;
    text-align: right;
    padding-right: 10px;
    line-height: 1.1;
    vertical-align: middle;
  }

  .fg-horizontal .form-group > div {
    display: table-cell;
  }
}
.fg .form-group {
  margin-bottom: 0;
}

.email-editor-hider {
  position: absolute;
  bottom: 0;
  background-color: rgb(238, 238, 238);
  right: 0;
  left: 0;
  height: 45px;
}
